<template>
  <app-overlay>
    <db-searchable-table v-model="filters" :items="items" :fields="fields" :total-rows="totalRows" searchable @changed="getData" :custom-page-options="[10, 25]">
      <template #transactionType="{ item }">
        <div class="d-flex no-wrap align-items-center">
          <span v-if="item.sales_invoice && item.sales_invoice.attributes.item_type === 'cancelled'">
            <mdi-icon icon="FileCancelOutline" />
          </span>
          <span>
            {{ transactionTypes[item.relation.attributes.transaction_type] }}
          </span>
        </div>
      </template>
      <template #amount="{ item }">
        <span :class="{ 'text-line-through': item.sales_invoice && item.sales_invoice.attributes.item_type === 'cancelled' }">
          {{ getAmount(item) }}
        </span>
      </template>
      <template #description="{ item }">
        <span :id="item.id">
          {{ $fn(getDescription(item), 20) }}
        </span>
        <b-tooltip v-if="getDescription(item) && getDescription(item).length > 20" :target="item.id" boundary="viewport" triggers="hover" placement="bottom">
          {{ getDescription(item) }}
        </b-tooltip>
      </template>
      <template #actions="{ item }">
        <app-dropdown v-if="item.relation.attributes.transaction_type === 'contact_credit'">
          <b-dropdown-item @click="deleteContactCredit(item)">
            <feather-icon icon="TrashIcon" class="mr-25" />
            Sil
          </b-dropdown-item>
          <b-dropdown-item @click="downloadPaymentReceipt(item)">
            <feather-icon class="mr-25" icon="FileTextIcon" />
            Tahsilat Makbuzu (PDF)
          </b-dropdown-item>
          <b-dropdown-item @click="showSendReceiptToEmailForm(item)">
            <feather-icon class="mr-25" icon="MailIcon" />
            E-Posta'ya Gönder
          </b-dropdown-item>
        </app-dropdown>
        <app-dropdown v-if="item.relation.attributes.transaction_type === 'sales_invoice'">
          <b-dropdown-item @click="showInvoiceDetails(item)"> <feather-icon class="mr-25" icon="FileTextIcon" /> Fatura Detayı </b-dropdown-item>
          <b-dropdown-item @click="showInvoiceCopyForm(item)"> <feather-icon class="mr-25" icon="CopyIcon" /> Kopya Oluştur </b-dropdown-item>
          <b-dropdown-item @click="getEInvoice(item)"><feather-icon class="mr-25" icon="DownloadCloudIcon" /> E-Fatura (PDF) </b-dropdown-item>
          <b-dropdown-item @click="showSendInvoiceToEmailForm(item)"> <feather-icon class="mr-25" icon="MailIcon" /> E-Postaya Gönder <br /> </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import SendReceiptToEmailForm from "../../InvoicePayments/SendReceiptToEmailForm.vue";
import InvoiceDetails from "../../invoices/InvoiceDetails/InvoiceDetails.vue";
import NewInvoiceForm from "../../invoices/NewInvoiceForm.vue";
import SendInvoiceToEmailForm from "../../invoices/SendInvoiceToEmailForm.vue";

export default {
  props: { id: String },
  data() {
    return {
      transactionTypes: {
        sales_invoice: "Satış Faturası",
        contact_credit: "Tahsilat",
      },
      items: [],
      included: [],
      filters: {},
      fields: [
        { key: "transactionType", label: "Tür" },
        { key: "description", label: "Açıklama" },
        { key: "relation.attributes.date", label: "İşlem Tarihi", formatDate: true },
        { key: "amount", label: "Tutar" },
        { key: "attributes.trl_balance", label: "Bakiye", formatToTL: true },
        { key: "actions", label: "İşlemler" },
      ],
      totalRows: 0,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    item() {
      return this.$store.getters.selectedParasutContact;
    },
  },
  methods: {
    getData() {
      if (this.selectedCompany?.parasut_contact_id || this.item?.contact_id)
        this.$axios
          .get(`/finance/contacts/${this.selectedCompany?.parasut_contact_id ?? this.item?.contact_id}/transaction-history`, { params: { ...this.filters }, loading: "table" })
          .then((result) => {
            this.totalRows = result.data?.meta?.total_count ?? 0;
            this.items = result.data?.data ?? [];
            this.included = result?.data?.included;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getAmount(item) {
      if (item?.relation?.attributes?.transaction_type === "contact_credit") {
        return `- ${this.$formatToTL(item?.relation?.attributes?.credit_amount)}`;
      }
      if (item?.relation?.attributes?.transaction_type === "sales_invoice") {
        return `${this.$formatToTL(item?.relation?.attributes?.debit_amount)}`;
      }
    },
    getDescription(item) {
      return item?.sales_invoice
        ? item?.sales_invoice?.attributes?.invoice_no + " - " + item?.sales_invoice?.attributes?.description
        : item?.relation?.attributes?.description ?? item?.relation?.attributes?.auto_description;
    },
    getInvoice(item, callback) {
      this.$axios
        .get("/finance/parasut-invoices/get-invoice", { params: { invoice_id: item?.sales_invoice?.id }, loading: "table" })
        .then((result) => {
          callback(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showInvoiceDetails(item) {
      this.getInvoice(item, (data) => {
        this.$showAppModal({ item: { data }, title: "Fatura Detayları", centered: false, size: "xl", component: InvoiceDetails });
      });
    },
    showInvoiceCopyForm(item) {
      this.getInvoice(item, (data) => {
        this.$showAppModal({ item: { copyItem: data }, title: "Yeni Fatura Oluştur", centered: false, size: "invoice", component: NewInvoiceForm });
      });
    },
    deleteContactCredit(item) {
      this.$confirm({ message: "Paraşüt ve sistem üzerinden kayıt ve kayda ait dosya varsa silinecektir. Onaylıyor musunuz?" }, () => {
        this.$axios
          .delete(`/finance/transactions/${item.id}`, { params: { contact_id: this?.item?.contact_id ?? this.selectedCompany?.parasut_contact_id }, loading: "table" })
          .then((result) => {
            this.getData();
            this.$route?.name === "finance-contact-detail" && this.$store.dispatch("getParasutContact", { id: this.$route.params?.id });
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: result.data.message ?? null });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err.data.message ?? null });
          });
      });
    },
    getEInvoice(item) {
      const data = item?.sales_invoice?.relationships?.active_e_document?.data;
      this.$axios
        .get(`/finance/parasut-invoices/get-e-invoice`, { params: { e_invoice_type: data?.type, e_invoice: data?.id }, loading: "table" })
        .then((result) => {
          if (result?.data?.url) window.open(result?.data?.url, "_blank");
        })
        .catch((err) => {
          this.$emitter?.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
        });
    },
    showSendInvoiceToEmailForm(item) {
      const data = item?.sales_invoice?.relationships?.active_e_document?.data;
      this.$showAppSidebar("E-Faturayı E-Postaya Gönder", SendInvoiceToEmailForm, {
        data: {
          e_invoice_type: data?.type,
          e_invoice: data?.id,
          invoice_no: item?.sales_invoice?.attributes?.invoice_no,
          contact: { id: item?.sales_invoice?.relationships?.contact?.data?.id },
        },
      });
    },
    downloadPaymentReceipt(item) {
      this.$store.dispatch("downloadPaymentReceipt", { id: item?.id, loading: "modal" });
    },
    showSendReceiptToEmailForm(item) {
      this.$showAppSidebar("Tahsilat Makbuzunu E-Posta'ya Gönder", SendReceiptToEmailForm, { data: { id: item?.id } });
    },
  },
  mounted() {
    this.$store.commit("setPaymentCallback", this.getData);
  },
  destroyed() {
    this.$store.commit("setPaymentCallback", null);
  },
};
</script>

<style>
.text-line-through {
  text-decoration: line-through;
}
</style>
