<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="contactNoteForm">
      <app-textarea-input v-model="note" name="Not" placeholder="Not giriniz..." label="Not:" rules="required" rows="5" />
      <app-button text="Oluştur" block @click="createNote" />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      note: null,
    };
  },
  computed: {
    item() {
      return this.$store.getters?.selectedParasutContact;
    },
    callback() {
      return this.$store.getters?.appSidebarItem?.callback;
    },
    selectedCompany() {
      return this.$store.getters?.selectedCompany;
    },
  },
  methods: {
    createNote() {
      this.$validate(this.$refs.contactNoteForm, () => {
        if (this.selectedCompany?.parasut_contact_id || this.item?.contact_id)
          this.$axios
            .post(
              "/finance/contacts/comments/new-comment",
              {
                contact_id: this.selectedCompany?.parasut_contact_id ?? this.item?.contact_id,
                note: this.note,
              },
              { loading: "sidebar" }
            )
            .then((result) => {
              this.callback && this.callback();
              this.$emitter.$emit("Notification", { title: "İşlem Başarılı", message: result?.data?.message, variant: "success" });
              this.$store.commit("cleanAppSidebar");
            })
            .catch((err) => {
              this.$emitter.$emit("Notification", { title: "Hata Oluştu", message: err?.data?.message, variant: "danger" });
            });
      });
    },
  },
};
</script>

<style></style>
