var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-overlay',[_c('db-searchable-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"total-rows":_vm.totalRows,"searchable":"","custom-page-options":[10, 25]},on:{"changed":_vm.getData},scopedSlots:_vm._u([{key:"transactionType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex no-wrap align-items-center"},[(item.sales_invoice && item.sales_invoice.attributes.item_type === 'cancelled')?_c('span',[_c('mdi-icon',{attrs:{"icon":"FileCancelOutline"}})],1):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.transactionTypes[item.relation.attributes.transaction_type])+" ")])])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-line-through': item.sales_invoice && item.sales_invoice.attributes.item_type === 'cancelled' }},[_vm._v(" "+_vm._s(_vm.getAmount(item))+" ")])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"id":item.id}},[_vm._v(" "+_vm._s(_vm.$fn(_vm.getDescription(item), 20))+" ")]),(_vm.getDescription(item) && _vm.getDescription(item).length > 20)?_c('b-tooltip',{attrs:{"target":item.id,"boundary":"viewport","triggers":"hover","placement":"bottom"}},[_vm._v(" "+_vm._s(_vm.getDescription(item))+" ")]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(item.relation.attributes.transaction_type === 'contact_credit')?_c('app-dropdown',[_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteContactCredit(item)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"TrashIcon"}}),_vm._v(" Sil ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadPaymentReceipt(item)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"FileTextIcon"}}),_vm._v(" Tahsilat Makbuzu (PDF) ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showSendReceiptToEmailForm(item)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"MailIcon"}}),_vm._v(" E-Posta'ya Gönder ")],1)],1):_vm._e(),(item.relation.attributes.transaction_type === 'sales_invoice')?_c('app-dropdown',[_c('b-dropdown-item',{on:{"click":function($event){return _vm.showInvoiceDetails(item)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"FileTextIcon"}}),_vm._v(" Fatura Detayı ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showInvoiceCopyForm(item)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"CopyIcon"}}),_vm._v(" Kopya Oluştur ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.getEInvoice(item)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"DownloadCloudIcon"}}),_vm._v(" E-Fatura (PDF) ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showSendInvoiceToEmailForm(item)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"MailIcon"}}),_vm._v(" E-Postaya Gönder "),_c('br')],1)],1):_vm._e()]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }