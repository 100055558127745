<template>
  <app-overlay loading-variable="modalOverlay">
    <b-card body-class="p-0" class="mb-50">
      <app-button @click="showNoteForm" class="flex-1-md mb-1" text="Not Ekle" icon="PlusIcon" size="md" />
      <b-alert variant="warning" :show="showAlert" fade>
        <div class="alert-body">
          <feather-icon class="mr-50" icon="InfoIcon" />
          <span>Henüz not eklenmemiştir. </span>
        </div>
      </b-alert>
      <b-card v-for="(item, key) in notes" :key="key" body-class="p-1" class="position-static border mb-75">
        <b-row style="gap: 10px 0" class="align-items-center">
          <b-col cols="11">
            <blockquote class="blockquote mb-0">
              <p v-if="item">
                {{ setNote(item) }}
              </p>
              <footer class="blockquote-footer">
                <small class="text-muted">
                  <cite v-if="item" title="Source Title">{{ setSourceTitle(item) }} </cite>
                </small>
              </footer>
            </blockquote>
          </b-col>
          <b-col cols="1">
            <app-icon-button @click="deleteNote(item)" variant="gradient-danger" icon="TrashIcon" />
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </app-overlay>
</template>

<script>
import ContactNoteForm from "./ContactNoteForm.vue";

export default {
  data() {
    return {
      showAlert: false,
      notes: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
  },
  watch: {
    notes() {
      this.showAlert = Boolean(!this.notes.length);
    },
  },
  mounted() {
    this.getNotes();
  },
  methods: {
    getNotes() {
      if (this.selectedCompany?.parasut_contact_id || this.item?.contact_id)
        this.$axios
          .get(`/finance/contacts/${this.selectedCompany?.parasut_contact_id ?? this?.item?.contact_id}/comments`, { loading: "modal" })
          .then((result) => {
            this.notes = result.data;
          })
          .catch((err) => {
            console.log(err);
          });
      else this.notes = [];
    },
    deleteNote(item) {
      this.$confirm({ message: "Not silinecek emin misiniz?" }, () => {
        this.$axios
          .delete(`/finance/contacts/comments/${item?.relation?.relationships?.item?.data?.id}`)
          .then((result) => {
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", message: result?.data?.message, variant: "success" });
            this.getNotes();
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", message: err?.data?.message, variant: "danger" });
          });
      });
    },
    showNoteForm() {
      this.$showAppSidebar("Not Ekle", ContactNoteForm, { callback: this.getNotes });
    },
    setNote(item) {
      return item?.relation?.attributes?.data?.comment;
    },
    setSourceTitle(item) {
      const commentor = item?.relation?.attributes?.data?.commentator_display_name;
      const created = this.$FormattedDateTime(item?.relation?.attributes?.date);

      return `${commentor} - ${created}`;
    },
  },
};
</script>

<style></style>
